const routes = [
  {
    path: "agents",
    name: "agents",
    component: () => import("@/views/agents/Index.vue"),
  },
  {
    path: "agent/:id",
    name: "agent",
    component: () => import("@/views/agents/View.vue"),
  },
  {
    path: "agent/:id/edit",
    name: "editAgent",
    component: () => import("@/views/agents/Edit.vue"),
  },
  {
    path: "agent/create",
    name: "createAgent",
    component: () => import("@/views/agents/Create.vue"),
  },
];

export default routes;
