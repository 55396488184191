const routes = [
  {
    path: "customers",
    name: "customers",
    component: () => import("@/views/customers/CustomersListing.vue"),
  },
  {
    path: "customers/view/:id",
    name: "customer",
    component: () => import("@/views/customers/CustomerDetails.vue"),
  },
  {
    path: "customers/:id/edit",
    name: "editCustomer",
    component: () => import("@/views/customers/Edit.vue"),
  },
  {
    path: "customers/create",
    name: "createCustomer",
    component: () => import("@/views/customers/Create.vue"),
  },
];

export default routes;
