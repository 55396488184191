const routes = [
  {
    path: "callRequests",
    name: "callRequests",
    component: () => import("@/views/call-request/CallRequests.vue"),
  },
  {
    path: "callRequests/history",
    name: "callRequestsHistory",
    component: () => import("@/views/call-request/CallRequestHistory.vue"),
  },
  {
    path: "callRequests/view/:id",
    name: "callrequest-view",
    component: () => import("@/views/call-request/View.vue"),
  },
];

export default routes;
