import axios, { AxiosInstance } from "axios";
import useTenant from "@/composables/useTenant";
import JwtService from "@/core/services/JwtService";

export interface RequestResponse<T> {
  error: unknown;
  data?: T;
}

export interface ResponseData<T> {
  data: {
    data: T | Array<T>;
  };
}

export const APP_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export const axiosClient: AxiosInstance = axios.create({
  baseURL: APP_BASE_URL,
  headers: {
    "Abp.TenantId": useTenant().fetchTenant()?.tenantId ?? null,
    Authorization: "Bearer " + JwtService.getToken(),
  },
});
