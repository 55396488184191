const routes = [
  {
    path: "products",
    name: "products",
    component: () => import("@/views/products/index.vue"),
  },
  {
    path: "products/view/:id",
    name: "product-view",
    component: () => import("@/views/products/View.vue"),
  },
  {
    path: "products/createOrEdit",
    name: "product-create-edit",
    component: () => import("@/views/products/CreateOrEdit.vue"),
  },
  {
    path: "products/product-units",
    name: "product-units",
    component: () => import("@/views/products/ProductUnit.vue"),
  },
  {
    path: "products/product-categories",
    name: "product-categories",
    component: () => import("@/views/products/ProductCategories.vue"),
  },
  {
    path: "product-bundles",
    name: "product-bundles",
    component: () => import("@/views/products/productBundle/index.vue"),
  },
  {
    path: "product-bundle/create-edit",
    name: "product-bundle-create-edit",
    component: () => import("@/views/products/productBundle/CreateOrEdit.vue"),
  },
  {
    path: "product-bundle/view",
    name: "product-bundle-view",
    component: () => import("@/views/products/productBundle/View.vue"),
  },
];

export default routes;
