import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { axiosClient, APP_BASE_URL } from "@/core/services/AxiosClient";
// import { TokenAuthServiceProxy } from "@/shared/service-proxies/service-proxies";
import { TokenAuthServiceProxy } from "@/shared/service-proxies/generated-proxies";
export interface User {
  name: string;
  surname: string;
  email: string;
  password: string;
  api_token: string;
}

export interface UserAuthInfo {
  error: string | null;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  error = null;
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();
  config = {};

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return JwtService.getUser();
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.error;
  }

  get getConfigurations() {
    return this.config;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    if (error.status == 401) {
      this.isAuthenticated = false;
      // this.$store.commit(Mutations.PURGE_AUTH);
      window.location.href = "/account/login";
    } else if (error.status == 400) {
      this.error = error.message;
    } else if (error.status == 500) {
      this.error = error.message;
    } else {
      this.error = { ...error.response };
    }
  }

  @Mutation
  [Mutations.SET_CUSTOM_ERROR](error) {
    this.error = error;
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user;
    this.error = null;
    JwtService.saveUser(user);
    JwtService.saveToken(user.accessToken, user.refreshToken);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
    JwtService.saveUser(user);
  }

  @Mutation
  [Mutations.GET_USER]() {
    this.user = JwtService.getUser();
  }

  @Mutation
  [Mutations.SET_CONFIG](config) {
    this.config = config;
    JwtService.saveConfigurations(config);
  }

  @Mutation
  [Mutations.GET_CONFIG]() {
    this.config = JwtService.getConfigurations();
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.error = null;
    JwtService.destroyToken();
    JwtService.destroyUser();
  }

  @Action
  async [Actions.LOGIN2](credentials) {
    const client = new TokenAuthServiceProxy(APP_BASE_URL, axiosClient);

    await client
      .authenticate(credentials)
      .then(async (data) => {
        this.context.commit(Mutations.SET_AUTH, data);
        await this.context.dispatch(Actions.GET_USER);
      })
      .catch(() => {
        this.context.commit(
          Mutations.SET_CUSTOM_ERROR,
          "Invalid username or password"
        );
      });
  }

  @Action
  [Actions.LOGIN](credentials) {
    return ApiService.post("TokenAuth/Authenticate", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data.result);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.error);
      });
  }

  @Action
  [Actions.GET_USER](credentials) {
    return ApiService.get(
      "api/services/app/Profile/GetCurrentUserProfileForEdit",
      credentials
    )
      .then(({ data }) => {
        console.log(data);
        this.context.commit(Mutations.SET_USER, data.result);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.error);
      });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("forgot_password", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.VERIFY_AUTH](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("verify_token", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
