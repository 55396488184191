import customers from "./customers";
import products from "./products";
import territories from "./territories";
import agents from "./agents";
import orders from "./orders";
import users from "./user";
import callRequest from "./callRequest";
import journeyPlan from "./journeyPlan";
import priceList from "./priceList";

const routes = [
  ...customers,
  ...products,
  ...callRequest,
  ...territories,
  ...agents,
  ...orders,
  ...users,
  ...journeyPlan,
  ...priceList,
];

export default routes;
