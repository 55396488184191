const routes = [
  {
    path: "price-lists",
    name: "priceLists",
    component: () => import("@/views/products/priceList/index.vue"),
  },
  {
    path: "price-lists/view/:id",
    name: "priceList-view",
    component: () => import("@/views/products/priceList/View.vue"),
  },
  {
    path: "price-lists/createOrEdit",
    name: "priceList-create-edit",
    component: () => import("@/views/products/priceList/CreateOrEdit.vue"),
  },
];

export default routes;
