const ID_TOKEN_KEY = "id_token" as string;
const ID_RECOVERY_TOKEN_KEY = "id_recovery_token" as string;
const RETAIL_ADMIN = "retail_admin" as string;
const RETAIL_CONFIG = "retail_config" as string;
/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const getUser = (): any | null => {
  const user = window.localStorage.getItem(RETAIL_ADMIN) as string;
  return JSON.parse(user);
};
export const getConfigurations = (): any | null => {
  const config = window.localStorage.getItem(RETAIL_CONFIG) as string;
  return JSON.parse(config);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string, recovery_token: string): void => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
  window.localStorage.setItem(ID_RECOVERY_TOKEN_KEY, recovery_token);
};

export const saveUser = (user: any): void => {
  window.localStorage.setItem(RETAIL_ADMIN, JSON.stringify(user));
};
export const saveConfigurations = (config: any): void => {
  window.localStorage.setItem(RETAIL_CONFIG, JSON.stringify(config));
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const destroyUser = (): void => {
  window.localStorage.removeItem(RETAIL_ADMIN);
};

export default {
  getToken,
  saveToken,
  destroyToken,
  getUser,
  saveUser,
  destroyUser,
  saveConfigurations,
  getConfigurations,
};
