import ApiService, { RequestResponse } from "@/core/services/ApiService";
import { Tenant } from "@/models";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";
import { AxiosRequestConfig } from "axios";
import { ref, Ref } from "vue";
interface UsableTenant {
  tenant: Ref<Tenant | null>;
  getTenant: (payload: AxiosRequestConfig) => Promise<boolean>;
  fetchTenant: () => Tenant | null;
  removeTenant: () => void;
}

const useTenant = (): UsableTenant => {
  const tenant: Ref<Tenant> = ref({} as unknown as Tenant);

  const getTenant = async (payload: any) => {
    const { error, data } = (await ApiService.post(
      "api/services/app/Account/IsTenantAvailable",
      payload
    )) as unknown as RequestResponse<Tenant>;

    if (!error) {
      const response = data?.result as unknown as Tenant;
      if (response.tenantId) {
        response.tenancyName = payload.tenancyName as unknown as string;
        const { id, tenancyName } = response;

        tenant.value = { id, tenancyName };
        localStorage.setItem("tenant", JSON.stringify(response));
        tenant.value = null as unknown as Tenant;
        return true;
      } else {
        return false;
      }
    } else {
      store.commit(Mutations.SET_ERROR, error);
    }
    return false;
  };

  const fetchTenant = () => {
    const data = localStorage.getItem("tenant");
    tenant.value = JSON.parse(data as string) as Tenant;

    return tenant.value ? tenant.value : null;
  };

  const removeTenant = () => {
    localStorage.removeItem("tenant");
    return;
  };

  return {
    tenant,
    fetchTenant,
    getTenant,
    removeTenant,
  };
};

export default useTenant;
