
  import { defineComponent, nextTick, onMounted } from "vue";
  import { useStore } from "vuex";
  import { Mutations } from "@/store/enums/StoreEnums";
  import { initializeComponents } from "@/core/plugins/keenthemes";

  export default defineComponent({
    name: "app",
    setup() {
      const store = useStore();

      onMounted(() => {
        /**
         * this is to override the layout config using saved data from localStorage
         * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
         */
        store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

        nextTick(() => {
          initializeComponents();
        });
      });
    },
  });
