const routes = [
  {
    path: "/admin/users",
    name: "users",
    component: () => import("@/views/admin/User/Index.vue"),
  },
  {
    path: "/admin/users/updateOrCreate",
    name: "user-updateOrCreate",
    component: () => import("@/views/admin/User/UpdateOrCreate.vue"),
  },
  {
    path: "/admin/users/view/:id",
    name: "user-view",
    component: () => import("@/views/admin/User/View.vue"),
  },
];

export default routes;
