const routes = [
  {
    path: "orders",
    name: "orders",
    component: () => import("@/views/orders/Index.vue"),
  },
  {
    path: "orders/view/:id",
    name: "order-view",
    component: () => import("@/views/orders/View.vue"),
  },
  {
    path: "orders/updateOrCreate",
    name: "order-create-edit",
    component: () => import("@/views/orders/UpdateOrCreate.vue"),
  },
];

export default routes;
